import { mkConfig, generateCsv, download } from 'export-to-csv';
import {
  GetTicketsForCsvExportQuery,
  ListMeetingServiceRequestsSortByCriteria,
  SortOrder,
  useGetLocationsByIdsForCsvExportLazyQuery,
  useGetTicketsForCsvExportLazyQuery,
} from 'generated/graphql';
import { Sentry } from 'lib/sentry';
import {
  eventTitleCases,
  mapAnswersToCSVData,
  mapTicketToCSVData,
} from 'lib/utility';
import moment from 'moment';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type MeetingServiceRequest =
  GetTicketsForCsvExportQuery['listMeetingServiceRequests']['meetingServiceRequests'][number];

export const useExportTicketsToCSV = () => {
  const { t } = useTranslation('TicketsListPage');
  const { toastMessage, fetchTicketsForTicketsListVariables } =
    useTicketsListPageContext();

  // We had issues where both lazy queries were being called twice. Once without a tenantId and failing
  // once with a tenantId and succeeding.  In order to avoid issues, only render this hook under a conditional
  // component that has a tenantId. i.e. if tenantId exists, render a button that instantiates this hook.
  const [getTicketsForCSVExport] = useGetTicketsForCsvExportLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [getLocationsByIdsForCSVExport] =
    useGetLocationsByIdsForCsvExportLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [isExporting, setIsExporting] = useState(false);

  const exportToCSV = useCallback(async () => {
    setIsExporting(true);
    try {
      // Hard limit on tickets returned to prevent long queries
      const limit = 10000;
      let allTickets: MeetingServiceRequest[] = [];
      // API responses will return whether or not this is the last page
      let hasNextPage = true;
      // Used for pagination within our queries
      let currentCursor: string | undefined;

      // The tickets GQL api does not have a good way to query for location/level names.
      // So store the ids here so we can do another lookup after to map names back
      const locationIds: string[] = [];
      const levelIds: string[] = [];

      // Loop using the cursor for pagination (better than skip).  Then end the loop after
      // we hit the arbitrary limit or the API tells us there are no more pages
      while (hasNextPage && allTickets.length <= limit) {
        const response = await getTicketsForCSVExport({
          variables: {
            ...fetchTicketsForTicketsListVariables({
              first: 50,
              after: currentCursor,
              sortBy: {
                criteria: ListMeetingServiceRequestsSortByCriteria.CreatedAt,
                order: SortOrder.Ascending,
              },
            }),
          },
        });

        const tickets: MeetingServiceRequest[] =
          response.data?.listMeetingServiceRequests?.meetingServiceRequests ||
          [];

        allTickets = [...allTickets, ...tickets];

        currentCursor =
          response.data?.listMeetingServiceRequests?.forwardPageInfo
            ?.endCursor || undefined;
        hasNextPage =
          response.data?.listMeetingServiceRequests?.forwardPageInfo
            ?.hasNextPage || false;
      }

      // The export-to-csv library does not 'merge' array items with unique column names
      // We treat each answer 'prompt' as a unique column so we need to manually
      // pull out all of the answers and then map them back to the ticket data
      const answersData = new Map<string, { [key: string]: string }>();

      // Map the responses to the CSV data map object
      const csvData = allTickets.map((ticket) => {
        // Adding the Ids to arrays so we can query for them after
        if (ticket.regardsEventAtSpace?.space?.locationId) {
          locationIds.push(
            ticket.regardsEventAtSpace.space.locationId.toString()
          );
        }
        if (ticket.regardsEventAtSpace?.space?.levelId) {
          levelIds.push(ticket.regardsEventAtSpace.space.levelId.toString());
        }

        const answers = mapAnswersToCSVData(ticket);
        if (answers) {
          answersData.set(ticket.key, answers);
        }

        // This is here so i can use translations
        const obfuscateEventTitle = (
          cases: ReturnType<typeof eventTitleCases>
        ): string => {
          switch (cases.type) {
            case 'visible':
              return cases.title;
            case 'sensitive':
              return t('csv.event_title_confidential');
            default:
              return t('csv.event_title_missing');
          }
        };

        const eventTitle = obfuscateEventTitle(
          eventTitleCases({
            visibility: ticket.regardsEventAtSpace?.event?.visibility || '',
            title: ticket.regardsEventAtSpace?.event?.title || '',
          })
        );
        return mapTicketToCSVData(ticket, eventTitle);
      });

      // Get the locations and level to find their names and map back to the data
      const locationsResponse = await getLocationsByIdsForCSVExport({
        variables: {
          locationIds: Array.from(new Set(locationIds)),
          levelIds: Array.from(new Set(levelIds)),
        },
      });

      // Create new Map objects for easy lookups using the ids as keys
      const locationMap = new Map(
        (locationsResponse.data?.getLocationsByIds || []).map((location) => [
          location.id,
          location.name,
        ])
      );
      const levelMap = new Map(
        (locationsResponse.data?.getLevelsByIds || []).map((level) => [
          level.id,
          level.name,
        ])
      );

      // First collect all unique prompts across all tickets to add as csv column headers
      const allPrompts = new Set<string>();
      answersData.forEach((answers) => {
        Object.keys(answers).forEach((prompt) => {
          allPrompts.add(prompt);
        });
      });

      // Find the names of each building and floor and add to the data
      csvData.forEach((ticket) => {
        ticket['Building Name'] =
          locationMap.get(ticket['Building Id'].toString()) || '';
        ticket['Floor Name'] =
          levelMap.get(ticket['Floor Id'].toString()) || '';

        // Initialize all prompt fields for every ticket with empty string so they
        // all have the same column names
        allPrompts.forEach((prompt) => {
          const ticketAnswers = answersData.get(ticket.Key);

          // If the ticket has answers for this prompt, set the value, otherwise leave it blank
          ticket[prompt] = ticketAnswers ? ticketAnswers[prompt] : '';
        });
      });

      // Generate the CSV with the proper options (see export-to-csv docs v1.4.0)
      const csvOptions = mkConfig({
        fieldSeparator: ',',
        quoteStrings: true,
        decimalSeparator: '.',
        useTextFile: false,
        filename: `tickets-export-${moment().format('YYYY-MM-DD-HH-mm-ss')}`,
        useKeysAsHeaders: true,
      });

      const csv = generateCsv(csvOptions)(csvData);
      download(csvOptions)(csv);

      // This should only be hit on successful download
      toastMessage('success', t('table.export_csv_success'));
    } catch (error) {
      toastMessage('error', t('table.export_csv_error'));
      Sentry.captureException(error);
    } finally {
      setIsExporting(false);
    }
  }, [
    getLocationsByIdsForCSVExport,
    toastMessage,
    t,
    getTicketsForCSVExport,
    fetchTicketsForTicketsListVariables,
  ]);

  return {
    isExporting,
    exportToCSV,
  };
};
