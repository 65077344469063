import { useCallback, useMemo } from 'react';
import {
  ListMeetingServiceRequestsFiltersInput,
  ListMeetingServiceRequestsSortByCriteria,
  ListMeetingServiceRequestsSortByInput,
  MeetingServiceRequestStatus,
  SortOrder,
  useGetMeetingServiceRequestsCountForTicketsListTablePaginationQuery,
  useListUniqueMeetingServiceRequestValuesLazyQuery,
} from 'generated';
import { useTenantLocalStorage } from 'hooks/useTenantLocalStorage';
import type { FilterValue, SorterResult } from 'antd/lib/table/interface';
import {
  FilterValues,
  TicketsListTableDataType,
} from './useManageTicketsListTable';

const DATE_RANGE_TICKET_LIST = 'date-range-tickets-list';
const MEETING_SERVICE_REQUEST_ADMIN_TABLE_FILTERS =
  'meeting-service-request-admin-table-filters';
const LIST_MEETING_SERVICE_REQUEST_TABLE_SORT =
  'meeting-service-request-admin-table-sort';

type NullableFilterValues<T> = {
  [P in keyof T]: T[P] | undefined;
};

export const useManageTicketListFiltersAndSort = (
  defaultDateRange: {
    startDate: string;
    endDate: string;
  },
  selectedLocationIds: string[],
  loadingLocations: boolean
) => {
  const [dateRange = defaultDateRange, setDateRange] = useTenantLocalStorage<{
    startDate: string;
    endDate: string;
  }>(DATE_RANGE_TICKET_LIST, defaultDateRange);

  const regardingAnyBuildingIdsOrTheirDescendants = useMemo(() => {
    return selectedLocationIds.filter((location) => !!location);
  }, [selectedLocationIds]);

  const [
    filtersForMeetingServiceRequestsQuery,
    setFiltersForMeetingServiceRequestsQuery,
  ] = useTenantLocalStorage<ListMeetingServiceRequestsFiltersInput>(
    MEETING_SERVICE_REQUEST_ADMIN_TABLE_FILTERS,
    {
      hasAnyStatus: [
        MeetingServiceRequestStatus.Todo,
        MeetingServiceRequestStatus.InProgress,
        MeetingServiceRequestStatus.NeedsApproval,
        MeetingServiceRequestStatus.Blocked,
      ],
      serviceHasAnyId: [],
      hasAnyAssignee: {
        groupIds: [],
        userIds: [],
      },
      hasAnyApprover: {
        groupIds: [],
        userIds: [],
      },
      hasAnyRequesterUserId: [],
      keyMatches: undefined,
      regardingAnySpaceIds: [],
      serviceBelongsToAnyCategoryIds: [],
    }
  );

  const filtersAreApplied = useMemo(() => {
    if (!filtersForMeetingServiceRequestsQuery) return false;
    const hasAnyAssignee =
      (filtersForMeetingServiceRequestsQuery.hasAnyAssignee?.userIds?.length ??
        0) > 0 ||
      (filtersForMeetingServiceRequestsQuery.hasAnyAssignee?.groupIds?.length ??
        0) > 0;
    const hasAnyApprover =
      (filtersForMeetingServiceRequestsQuery.hasAnyApprover?.userIds?.length ??
        0) > 0 ||
      (filtersForMeetingServiceRequestsQuery.hasAnyApprover?.groupIds?.length ??
        0) > 0;
    const hasAnyStatus =
      (filtersForMeetingServiceRequestsQuery.hasAnyStatus?.length ?? 0) > 0;
    const hasAnyRequester =
      (filtersForMeetingServiceRequestsQuery.hasAnyRequesterUserId?.length ??
        0) > 0;
    const hasAnyServiceBelongsToAnyCategory =
      (filtersForMeetingServiceRequestsQuery.serviceBelongsToAnyCategoryIds
        ?.length ?? 0) > 0;
    const hasAnyServiceHasAnyId =
      (filtersForMeetingServiceRequestsQuery.serviceHasAnyId?.length ?? 0) > 0;
    const hasKeyMatches =
      filtersForMeetingServiceRequestsQuery.keyMatches !== null;

    return (
      hasAnyAssignee ||
      hasAnyApprover ||
      hasAnyStatus ||
      hasAnyRequester ||
      hasAnyServiceBelongsToAnyCategory ||
      hasAnyServiceHasAnyId ||
      hasKeyMatches
    );
  }, [filtersForMeetingServiceRequestsQuery]);

  const {
    data: dataForCountServiceRequests,
    refetch: refetchMeetingServiceRequestsCount,
  } = useGetMeetingServiceRequestsCountForTicketsListTablePaginationQuery({
    variables: {
      input: {
        filters: {
          eventStartTimeBetween: {
            start: dateRange.startDate,
            end: dateRange.endDate,
          },
          regardingAnyBuildingIdsOrTheirDescendants,
          ...filtersForMeetingServiceRequestsQuery,
        },
      },
    },
    skip:
      loadingLocations || regardingAnyBuildingIdsOrTheirDescendants.length < 1,
  });

  /* Might be a useful query to have so we can request the proper amount of unique items
  @TODO: rework
  */
  // const { data: dataForCountServiceRequestsUnique } =
  //   useGetMeetingServiceRequestsCountForTicketsListTablePaginationQuery({
  //     variables: {
  //       input: {
  //         filters: {
  //           eventStartTimeBetween: {
  //             start: dateRange.startDate,
  //             end: dateRange.endDate,
  //           },
  //           regardingAnyBuildingIdsOrTheirDescendants,
  //         },
  //       },
  //     },
  //     skip:
  //       loadingLocations ||
  //       regardingAnyBuildingIdsOrTheirDescendants.length < 1,
  //   });

  // const totalUniqueMeetingServiceRequestValuesToGet = useMemo(() => {
  //   return (
  //     dataForCountServiceRequestsUnique?.countMeetingServiceRequests.count || 0
  //   );
  // }, [dataForCountServiceRequests]);

  const [
    getUniqueMeetingServiceRequestValues,
    { data: uniqueMeetingServiceRequestValues },
  ] = useListUniqueMeetingServiceRequestValuesLazyQuery({
    variables: {
      input: {
        /* @TODO: rework
         * Using a filtered count query to get all unique values is a bad idea
         * Accounting for 10 pages of 15 items
         */
        first: 150,
        filters: {
          regardingAnyBuildingIdsOrTheirDescendants: selectedLocationIds,
          eventStartTimeBetween: {
            start: dateRange.startDate,
            end: dateRange.endDate,
          },
        },
      },
    },
  });

  const buildUserOrGroupsFilterForQuery = useCallback(
    (ids: string[] | null, type: 'assignee' | 'approver') => {
      if (!ids || ids.length === 0) {
        return undefined;
      }
      const groupIds: string[] = [];
      const userIds: string[] = [];
      const typeToGet = type === 'assignee' ? 'assignees' : 'approvers';

      ids.forEach((id) => {
        const userOrGroupById =
          uniqueMeetingServiceRequestValues?.listUniqueMeetingServiceRequestValues.uniqueValues[
            typeToGet
          ].find((a) => a?.id === id);

        if (userOrGroupById?.__typename === 'Group') {
          groupIds.push(userOrGroupById.id);
        }

        if (userOrGroupById?.__typename === 'User') {
          userIds.push(userOrGroupById.id);
        }
        return;
      });

      return { userIds, groupIds };
    },
    [uniqueMeetingServiceRequestValues]
  );

  const convertTableFilters = (
    tableFilter: Record<string, FilterValue | null>
  ): NullableFilterValues<FilterValues> => {
    return {
      status:
        (tableFilter.status as MeetingServiceRequestStatus[] | undefined) ??
        undefined,
      serviceId: (tableFilter.serviceName as string[] | undefined) ?? undefined,
      requester: (tableFilter.requester as string[] | undefined) ?? undefined,
      approver: (tableFilter.approver as string[] | undefined) ?? undefined,
      assignee: (tableFilter.assignee as string[] | undefined) ?? undefined,
      space: (tableFilter.space as string[] | undefined) ?? undefined,
      category: (tableFilter.category as string[] | undefined) ?? undefined,
      keyMatches: tableFilter.serviceRequestKey
        ? (tableFilter.serviceRequestKey[0] as string | undefined) ?? undefined
        : undefined,
    };
  };

  const handleClearAllFilters = useCallback(() => {
    setFiltersForMeetingServiceRequestsQuery({
      hasAnyStatus: [],
      serviceHasAnyId: [],
      hasAnyRequesterUserId: [],
      hasAnyApprover: {
        groupIds: [],
        userIds: [],
      },
      hasAnyAssignee: {
        groupIds: [],
        userIds: [],
      },
      regardingAnySpaceIds: [],
      serviceBelongsToAnyCategoryIds: [],
      keyMatches: null,
    });
  }, [setFiltersForMeetingServiceRequestsQuery]);

  const handleSetFiltersForMeetingServiceRequestsQuery = useCallback(
    (tableFilters: Record<string, FilterValue | null>) => {
      const convertedTableFilters = convertTableFilters(tableFilters);
      const filtersForMeetingServiceRequestsQuery: ListMeetingServiceRequestsFiltersInput =
        {
          hasAnyStatus: convertedTableFilters.status
            ? [
                ...(convertedTableFilters.status as MeetingServiceRequestStatus[]),
              ]
            : [],
          serviceHasAnyId: convertedTableFilters.serviceId
            ? [...convertedTableFilters.serviceId]
            : [],
          hasAnyRequesterUserId: convertedTableFilters.requester
            ? [...convertedTableFilters.requester]
            : [],
          hasAnyApprover: convertedTableFilters.approver
            ? buildUserOrGroupsFilterForQuery(
                convertedTableFilters.approver,
                'approver'
              )
            : {
                groupIds: [],
                userIds: [],
              },
          hasAnyAssignee: convertedTableFilters.assignee
            ? buildUserOrGroupsFilterForQuery(
                convertedTableFilters.assignee,
                'assignee'
              )
            : {
                groupIds: [],
                userIds: [],
              },
          regardingAnySpaceIds: convertedTableFilters.space
            ? [...convertedTableFilters.space]
            : [],
          serviceBelongsToAnyCategoryIds: convertedTableFilters.category
            ? [...convertedTableFilters.category]
            : [],
          keyMatches: convertedTableFilters.keyMatches
            ? convertedTableFilters.keyMatches
            : undefined,
        };

      setFiltersForMeetingServiceRequestsQuery((prev) => {
        return {
          ...prev,
          ...filtersForMeetingServiceRequestsQuery,
        };
      });
    },
    [setFiltersForMeetingServiceRequestsQuery, buildUserOrGroupsFilterForQuery]
  );

  const [
    sortByForListServiceRequestsQuery,
    setSortByForListServiceRequestsQuery,
  ] = useTenantLocalStorage<ListMeetingServiceRequestsSortByInput | null>(
    LIST_MEETING_SERVICE_REQUEST_TABLE_SORT,
    null
  );

  const handleSetSortByForListServiceRequestsQuery = useCallback(
    (
      sorter:
        | SorterResult<TicketsListTableDataType>
        | SorterResult<TicketsListTableDataType>[]
    ) => {
      /* We can't handle multiple sorter objects yet */
      if (Array.isArray(sorter)) {
        return;
      }

      const sortConfig =
        /* if order or columnKey is not present, we want to reset sort */
        !sorter || !sorter.order || !sorter.columnKey
          ? null
          : {
              order:
                sorter.order === 'ascend'
                  ? SortOrder.Ascending
                  : SortOrder.Descending,
              columnKey: sorter.columnKey as string,
            };

      if (!sortConfig) {
        setSortByForListServiceRequestsQuery(null);
        return;
      }

      const criteria = (() => {
        switch (sortConfig.columnKey as string) {
          case 'createdAt':
            return ListMeetingServiceRequestsSortByCriteria.CreatedAt;
          case 'updatedAt':
            return ListMeetingServiceRequestsSortByCriteria.UpdatedAt;
          case 'status':
            return ListMeetingServiceRequestsSortByCriteria.Status;
          case 'serviceRequestKey':
            return ListMeetingServiceRequestsSortByCriteria.Key;
          case 'startAt':
            return ListMeetingServiceRequestsSortByCriteria.EventStartTime;
          case 'serviceName':
            return ListMeetingServiceRequestsSortByCriteria.ServiceName;
          case 'category':
            return ListMeetingServiceRequestsSortByCriteria.ServiceCategoryName;
          default:
            return ListMeetingServiceRequestsSortByCriteria.UpdatedAt;
        }
      })();

      setSortByForListServiceRequestsQuery({
        order: sortConfig.order,
        criteria,
      });
    },
    [setSortByForListServiceRequestsQuery]
  );

  return {
    dataForCountServiceRequests,
    refetchMeetingServiceRequestsCount,
    regardingAnyBuildingIdsOrTheirDescendants,
    dateRange,
    setDateRange,
    filtersForMeetingServiceRequestsQuery,
    handleSetFiltersForMeetingServiceRequestsQuery,
    handleClearAllFilters,
    sortByForListServiceRequestsQuery,
    handleSetSortByForListServiceRequestsQuery,
    setFiltersForMeetingServiceRequestsQuery,
    getUniqueMeetingServiceRequestValues,
    uniqueMeetingServiceRequestValues,
    filtersAreApplied,
  };
};
